<template>
  <div>
    <NavBarFreeMain />
    <div class="calendar-container">
      <div class="calendar-container__calendar-view">
        <div role="button" class="calendar-container__calendar-view__back-button" @click="onClickBack">
          <div class="image">
            <img src="/icons/icn_arrow_back.svg" />
          </div>
          <div class="text">Volver</div>
        </div>
        <div class="calendar-container__calendar-view__calendar">
          <CalendarCup v-if="isCup" await-for-seasons :seasons="calendarSeasons" :show-logos="false" />
          <CalendarLanding
            v-else
            type="onlycomponent"
            await-for-seasons
            is-green-header-color
            is-full
            :seasons="calendarSeasons"
            :show-logos="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'CalendarView',
  props: {
    predictions: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    NavBarFreeMain: () => import('@/components/Sections/Main/NavBarFreeMain'),
    // Calendar: () => import('@/components/Sections/Calendar/CalendarComponent'),
    CalendarCup: () => import('@/components/Sections/Calendar/CalendarCup'),
    CalendarLanding: () => import('@/components/Sections/Calendar/CalendarComponentLanding'),
  },
  data() {
    return {
      isCup: false,
      calendarSeasons: [],
    };
  },
  methods: {
    ...mapMutations('general', ['setTournamentSelected']),
    onTournamentSelected(tournament) {
      this.setTournamentSelected(tournament);
    },
    onClickBack() {
      if (this.$route.query.team) {
        this.$router.push(`/widget/${this.$route.query.team}`);
      } else {
        this.$router.push({ name: 'landing' });
      }
    },
  },
  created() {
    if (this.$route.query.seasons && typeof this.$route.query.seasons === 'string') {
      this.calendarSeasons = JSON.parse(atob(this.$route.query.seasons));
      this.isCup = Number(this.$route.query.isCup) === 0 ? false : true;
    }
  },
};
</script>

<style lang="scss">
html > body {
  padding-top: 0 !important;
}
.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: calc(100% - 1px);
}
.calendar-container {
  display: flex;
  justify-content: center;

  &__calendar-view {
    width: 1240px;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 8px;

    &__back-button {
      padding: 9px 20px;
      margin: 32px 0;
      border-radius: 10px;
      color: white;
      background-color: #132839;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      & .image {
        height: 32px;
        width: 32px;
      }

      & .text {
        margin-left: 17px;
      }
    }

    &__calendar {
      width: 100%;
    }
  }
}
</style>
